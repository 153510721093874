import React, { useState, useEffect } from "react";
import "./BlogFurtherReading.css";
import { fetchBlog } from "../../api/blogpost";
import BlogPostCard from "../BlogPostCard";
import { Link } from "gatsby";
import Fade from "../../animations/Fade";

export default function BlogFurtherReading({ blog }) {
  const [blogs, setBlogs] = useState([]);

  const fetchBlogs = async () => {
    const data = await fetchBlog();
    setBlogs(data);
  };

  useEffect(() => {
    fetchBlogs();
  }, []);

  let current = 0;

  const previous = () => {
    if (current == 0) {
      current = blogs.length - 1;
      console.log(current);
    } else {
      current--;
      console.log(current);
    }
  };

  const next = () => {
    if (current == blogs.length - 1) {
      current = 0;
      console.log(current);
    } else {
      current++;
      console.log(current);
    }
  };

  return (
    <div className="mt-36">
      <div className="further-reading mb-8 flex justify-between">
        <h1 className="xs:text-3xl sm:text-4xl md:text-4xl lg:text-4xl xl:text-4xl tracking-tighter font-medium">
          YOU ALSO MIGHT LIKE
        </h1>
        <p className="mt-8">FURTHER READING</p>
      </div>
      <hr />
      <Fade direction={"bottomToTop"} duration={1}>
        <div
          className="blog grid gap-y-6 gap-x-6 grid-cols-3 md:mx-20 lg:mx-20
          sm:mx-10 xs:mx-5 content-around"
        >
          {blogs.length === 0 ? (
            <div>No post found</div>
          ) : (
            blogs.slice(0, 4).map((item, index) => {
              return (
                index !== 0 && (
                  <Link
                    key={item.guid}
                    to={`/articles/${item.title.replaceAll("#", "")}`}
                  >
                    <BlogPostCard
                      title={item.title.toUpperCase()}
                      thumbnail={item.thumbnail}
                      pubDate={item.pubDate}
                      content={item.content}
                      btnName="Press Release"
                    />
                  </Link>
                )
              );
            })
          )}
        </div>
      </Fade>
      <div className="mt-24 flex justify-between">
        <Link to={`/articles/${blog.title.replaceAll("#", "")}`}>
          <button
            onClick={previous}
            type="button"
            className="inline-block px-6 py-2 border-2 border-gray-200 text-gray-200 font-medium text-xs leading-tight uppercase rounded-full hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
          >
            {"< PREVIOUS "}{" "}
          </button>
        </Link>
        <Link to={`/articles/${blog.title.replaceAll("#", "")}`}>
          <button
            onClick={next}
            type="button"
            className="inline-block px-6 py-2 border-2 border-gray-200 text-gray-200 font-medium text-xs leading-tight uppercase rounded-full hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
          >
            {"NEXT >"}{" "}
          </button>
        </Link>
      </div>
    </div>
  );
}
