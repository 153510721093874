import React, {useState, useEffect} from 'react';
import { formatDate, readingTime } from "../../utils/utils";
import BlogFurtherReading from '../BlogFurtherReading';
import "./BlogBannerDetail.css";

export default function BlogDetailBanner({ blog }){
  const [imageElement, setImageElement] = useState("")

  useEffect(() =>{
    if (document !== undefined) {
      let elem = document.createElement('div');
      elem.style.display = 'none';
      document.body.appendChild(elem);
      elem.innerHTML = blog.content
      setImageElement(elem.querySelector('img').src) ;
    }
    
  }, [])

  return(
    <div className='mt-64'>
      <div className='flex justify-between'>
        <p>Published on</p>
        <p className='time sm:hidden  md:hidden'>READ TIME</p>
      </div>
      <div className='flex justify-between'>
        <p>{formatDate(blog.pubDate)}</p>
        <p className='time sm:hidden md:hidden'>{readingTime(blog.content)}</p>
      </div>
      <h2 className='heading articles my-24'>{blog.title}</h2>
      <div className='flex justify-between'>
        <p className='description w-7/12' dangerouslySetInnerHTML={{__html: `${blog.description.replace(/<img[^>]*>/g,"").split(".", 1)[0]}`}}></p>
        <button
          type="button"
          data-mdb-ripple="true"
          data-mdb-ripple-color="light"
          className='release-btn'>
          Press Release
        </button>
      </div>
      <p dangerouslySetInnerHTML={{__html: `${blog.description.split(/<img[^>]*>/g, 1)[0]}`}}></p>
      {/* <img className='w-full mt-24' src={imageElement.querySelector('img').src} alt="blog image"/> */}
      <img className='w-full mt-24' src={imageElement} alt="blog image"/>

      <h1 className='text-3xl mt-8'>{blog.title.toUpperCase()}</h1>
      <div className='content flex justify-between mt-16'>
        <p className='w-11/12 mb-8'>{formatDate(blog.pubDate)}</p>
        <p dangerouslySetInnerHTML={{__html: `${blog.content.replace(/<img[^>]+>/, '')}`} }></p>
      </div>
      <BlogFurtherReading blog={blog}/>
    </div>
  )
}