import React from 'react';
import { Helmet } from "react-helmet";
import Layout from "../components/Layout";
import BlogDetailBanner from '../components/BlogDetailBanner';
import MediaAlert from '../components/mediaAlert';

export default function  BlogDetail({ pageContext: { blog } }){
  return(
    <Layout>
      <Helmet>
          <title>Algomint | Blog Detail </title>
      </Helmet>
      <MediaAlert/>
      <BlogDetailBanner blog={blog}/>
    </Layout>
  )
}
